<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3>음원이 삭제되었습니다.</h3>
            <div class="single-file-check">
                <p @click="$emit('closeDeletePopup')">확인</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'appendDeleteConfirm'
};
</script>

<style scoped>
  .alert {
    background: none;
  }
</style>
