<template>
    <div class="set_time_popup modal">
        <div class="building set_time_box modal_box">
            <div class="set_time_title modal_title">
                <h2>시간설정</h2>
                <div class="modal_close_btn" @click="$emit('closeModal')">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9 7.55012L16.0003 0.549805L18 2.54949L10.9997 9.5498L18 16.5501L16.0003 18.5498L9 11.5495L1.99969 18.5498L0 16.5501L7.00031 9.5498L0 2.54949L1.99969 0.549805L9 7.55012Z" fill="white" />
                    </svg>
                </div>
            </div>
            <div class="set_select_time">
                <div @click="selectPlayType('S')">
                    <input type="radio" name="chk_info" id="select" :checked="playType === 'S'" />
                    <label>지정시간</label>
                </div>
                <div :class="{ disabled: playType !== 'S' }">
                    <TimePicker :time="selectTime" :playType="'S'" @setTime="setTime" />
                </div>
            </div>
            <span class="borderline"></span>
            <div class="set_repeat_time">
                <div @click="selectPlayType('R')">
                    <input type="radio" name="chk_info" id="repeat" :checked="playType === 'R'" />
                    <label>반복주기</label>
                </div>
                <div class="set_time_container">
                    <span class="set_time_label">운영시간</span>
                    <ul class="set_time_wrapper">
                        <li class="set_time_item" :class="{ set_time_item_full: !isbuilding }">
                            <SetTimePicker :text="'부터'" :disabled="playType !== 'R'" :time="startTime" @setTime="setTime" />
                        </li>
                        <li class="set_time_item" v-if="isbuilding">
                            <SetTimePicker :text="'까지'" :disabled="playType !== 'R'" :time="endTime" @setTime="setTimeEnd" />
                        </li>
                    </ul>
                    <span class="set_time_label">주기</span>
                    <ul class="set_time_wrapper">
                        <li class="set_time_item_full">
                            <RepeatTime :disabled="playType !== 'R'" :time="repeatTime" @setTime="setRepeatTime" />
                        </li>
                    </ul>
                </div>
            </div>
            <div class="btn_area">
                <button @click="$emit('closeModal')">취소</button>
                <button @click="updateBuildingSoundTime()">확인</button>
            </div>
        </div>
    </div>
</template>

<script>
import TimePicker from '@/components/modal/common/TimePicker.vue';
import RepeatTime from '@/components/modal/common/RepeatTime.vue';
import SetTimePicker from '@/components/modal/common/SetTimePicker.vue';
import axios from 'axios';
import VueCookies from 'vue-cookies';
export default {
  name: 'setTimeBuildingSound',
  data () {
    return {
      playType: '',
      selectTime: null,
      repeatTime: null,
      startTime: null,
      endTime: null
    };
  },
  props: {
    musicInfo: Object,
    isbuilding: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  components: {
    TimePicker,
    RepeatTime,
    SetTimePicker
  },
  created () {
    // 넘겨받은 시간 셋팅 playType값이 null이면 S 지정
    // this.musicInfo.mySoundDetail.playType == null ? this.playType = 'S' : this.playType = this.musicInfo.mySoundDetail.playType;
    this.playType = this.musicInfo.mySoundDetail.playType;
    this.selectTime = this.musicInfo.mySoundDetail.selectTime;
    this.repeatTime = this.musicInfo.mySoundDetail.repeatTime;
    this.startTime = this.musicInfo.mySoundDetail.startTime;
    this.endTime = this.musicInfo.mySoundDetail.endTime;
  },
  methods: {
    setRepeatTime (value) {
      this.repeatTime = value;
    },
    setTime (value) {
      this.playType === 'S' ? (this.selectTime = value) : (this.startTime = value);
    },
    setTimeEnd (value) {
      this.endTime = value;
    },
    timeSetting (value) {
      // timepicker창 오픈 시 셋팅된 값 넘겨줌
      value === 'S' ? (this.time = this.selectTime) : (this.time = this.startTime);
      this.isTimepicker = true;
    },
    selectPlayType (value) {
      // radio버튼 클릭 playType 선택
      this.playType = value;
    },
    changeTime (value, type) {
      // timepicker로부터 넘겨받은 값 셋팅
      type === 'S' ? (this.selectTime = value) : (this.startTime = value);
    },
    updateBuildingSoundTime () {
      // playType가 S일때 지정 시간, R일때 반복 시간 업데이트
      this.playType === 'S' ? this.updateSelectTime() : this.updateStartTime();
    },
    updateSelectTime () {
      // 음원 지정 시간 업데이트
      const selectTime = this.selectTime;
      const brandMusicId = this.musicInfo.brandMusicId;
      const spaceId = this.$store.state.division !== 'Building' ? VueCookies.get('spaceId') : VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/player/updateSpaceMusicSelectTime', { spaceId, brandMusicId, selectTime }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            this.$emit('getList');
            this.$emit('closeModal');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateStartTime () {
      // 음원 반복 시간 업데이트
      const startTime = this.startTime;
      const endTime = this.endTime;
      const brandMusicId = this.musicInfo.brandMusicId;
      const spaceId = this.$store.state.division !== 'Building' ? VueCookies.get('spaceId') : VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/player/updateSpaceMusicStartTime', { spaceId, brandMusicId, startTime, endTime }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            this.updateRepeatTime();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    updateRepeatTime () {
      // 음원 반복 주기 업데이트
      const repeatTime = String(this.repeatTime);
      const brandMusicId = this.musicInfo.brandMusicId;
      const spaceId = this.$store.state.division !== 'Building' ? VueCookies.get('spaceId') : VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      axios
        .post('/api/player/updateSpaceMusicRepeatTime', { spaceId, brandMusicId, repeatTime }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            this.$emit('getList');
            this.$emit('closeModal');
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
.set_time_popup {
  background: rgba(0, 0, 0, 0.7);
}

.building.set_time_box {
  width: 540px;
}

.building.set_time_box .set_time_title h2 {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.building div div label {
  margin-bottom: 0;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -1px;
  cursor: pointer;
}

.building div div input[type='radio'] {
  appearance: none;
  background: rgba(255, 255, 255, 0.16);
  width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
}

.building div div input[type='radio']:hover {
  background: rgba(255, 255, 255, 0.2);
}

.building div div input[type='radio']:checked {
  background: #ff2d55;
  border: #fff 5px solid;
}

.building .set_select_time > div:nth-child(1) {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
}

.building .set_select_time > div:nth-child(2) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  padding: 14px 0;
  margin-bottom: 12px;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.04);
  border-radius: 8px;
  cursor: pointer;
}

.building .set_select_time > div:nth-child(2) p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: rgba(255, 255, 255, 0.87);
}

.building .set_repeat_time > div:nth-child(1) {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
}

.building .set_repeat_time > div:nth-child(2) {
  display: flex;
}

.building .set_repeat_time > div:nth-child(2) > div:nth-child(1) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.04);
  border-right: 0;
  cursor: pointer;
  border-radius: 8px 0px 0px 8px;
}

.building .set_repeat_time > div:nth-child(2) div p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: rgba(255, 255, 255, 0.87);
}

.building .set_repeat_time > div:nth-child(2) div.select-box-area,
.select-box {
  border-radius: 0px 8px 8px 0px;
  background: rgba(255, 255, 255, 0.08);
}

.label.select-box {
  border-radius: 8px;
}

.select-box {
  background: transparent !important;
}

.building .btn_area {
  margin-top: 16px;
}

.building .btn_area button:nth-child(1) {
  background: transparent;
  border-radius: 10px;
  margin-right: 8px;
}

.building .btn_area button:nth-child(1):hover {
  background: rgba(255, 255, 255, 0.04);
}

.disabled {
  border-radius: 8px;
}

.disabled,
.disabled > div {
  pointer-events: none;
  cursor: default;
  background: rgba(255, 255, 255, 0.04) !important;
}

.disabled p,
.disabled .label.select-box {
  color: rgba(255, 255, 255, 0.15) !important;
}

.disabled svg,
.disabled .select-box::after {
  opacity: 0.3;
}

.select-box.disabled::after {
  opacity: 0.3;
}

.borderline {
  display: block;
  width: 100%;
  height: 1px;
  margin-bottom: 12px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
}
.set_time_container {
  display: flex;
  flex-direction: column;
}
.set_time_wrapper {
  display: flex;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 12px;
  justify-content: space-between;
}

.set_time_item {
  width: 49.8%;
}

.set_time_item_full {
  width: 100%;
}

.set_time_label {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
</style>
<style src="@/assets/css/font.css"></style>
