<template>
    <div>
        <div class="single-file-alert alert">
            <div class="single-file-box alert_box">
                <h3>음원을 삭제하시겠습니까?</h3>
                <div class="single-file-btn">
                    <p @click="$emit('closeConfirm')">아니오</p>
                    <p id="color-text" @click="deleteFile()">네</p>
                </div>
            </div>
        </div>

        <appendDeleteConfirm v-if="append" @closeDeletePopup="closeDeletePopup()"></appendDeleteConfirm>
    </div>
</template>

<script>
import appendDeleteConfirm from '../confirm/AppendDeleteConfirm.vue';
import VueCookies from 'vue-cookies';
import axios from 'axios';

export default {
  name: 'mysoundDeleteConfirm',

  components: {
    appendDeleteConfirm
  },

  props: [
    'musicInfo'
  ],

  data () {
    return {
      append: false
    };
  },

  methods: {
    deleteFile () {
      // 파일 삭제
      const userId = VueCookies.get('userId');
      const brandMusicId = this.musicInfo.brandMusicId;
      const mySoundDetail = {
        brandSpaceMusicId: this.musicInfo.mySoundDetail.brandSpaceMusicId
      };
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post('/api/mysound/deleteMySound', { userId, brandMusicId, mySoundDetail }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.append = true;
          }
        })
        .catch(e => {
          console.log(e);
        });
    },

    closeDeletePopup () {
      this.append = false;
      this.$emit('closeDeletePopup');
    }
  }
};
</script>

<style scoped>

</style>
