<template>
    <div>
        <div class="building_modal modal1">
            <div class="building_modal_box modal_box">
                <div class="building_modal_title modal_title">
                    <h2>적용 스페이스 선택</h2>
                    <div class="building_sound_title">
                        <img src="/media/img/building_sound_icon.svg" />
                        <div v-html="brandMusicNm" />
                        <div class="modal_close_btn" @click="$emit('closeModal')">
                            <img src="/media/images/icon_close_24.svg" alt="" />
                        </div>
                    </div>
                </div>
                <div class="building_modal_body">
                    <div class="body_left">
                        <div class="body_left_top">
                            <ul>
                                <li>
                                    <span class="check" @click="selectAll($event)">
                                        <img src="/media/img/icon_check1_on.svg" v-if="checkboxAll" />
                                        <img src="/media/img/icon_check1_off.svg" v-else />
                                        <input type="checkbox" v-model="checkboxAll" />
                                    </span>
                                    <span>전체 스페이스</span>
                                    <span>{{ spaceList.length }}</span>
                                </li>
                            </ul>
                        </div>
                        <div class="body_left_body">
                            <ul>
                                <li v-for="(item, index) in spaceList" :key="index">
                                    <span class="check" @click="selcetSpace(index, item, $event)">
                                        <img src="/media/img/icon_check1_on.svg" v-if="checkboxList[index]" />
                                        <img src="/media/img/icon_check1_off.svg" v-else />
                                        <input type="checkbox" v-model="checkboxList[index]" />
                                    </span>
                                    <span v-html="item.spaceNm" />
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="body_right">
                        <div class="body_right_top">
                            <div><span>선택됨</span><span>{{ selectedSpaceList.length }}</span></div>
                            <div @click="deselectAll()">모두 해제</div>
                        </div>
                        <div class="body_right_body" v-if="selectedSpaceList.length > 0">
                            <ul>
                                <li v-for="(item, index) in selectedSpaceList" :key="index">
                                    <span v-html="item.spaceNm" />
                                    <span><img src="/media/images/icon_close_24.svg" alt="" @click="deselectSpace(item)"/></span>
                                </li>
                            </ul>
                        </div>
                        <div class="body_right_body" v-else>
                            <span>적용하실 스페이스를<br>선택해주세요.</span>
                        </div>
                    </div>
                </div>
                <div class="building_modal_bottom">
                    <button @click="$emit('closeModal')">취소</button>
                    <button @click="applySpaceByBuildingSound()">확인</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import VueCookies from "vue-cookies";

export default {
  name: 'SelectBuildingSpaceModal',
  data () {
    return {
      checkboxList: [],
      spaceList: [],
      selectedSpaceList: [],
      checkboxAll: false
    };
  },
  props: {
    brandSpaceMusicId: String,
    brandMusicId: String,
    brandMusicNm: String,
    playAt: String,
    spaceIdList: Array
  },
  mounted () {
    this.getSubscribeSpaceList();
  },
  methods: {
    deselectAll () { // 모두 해제 기능(우측)
      this.selectedSpaceList = [];
      for (const i in this.checkboxList) {
        this.checkboxList[i] = false;
      }
      this.checkboxAll = false;
    },
    deselectSpace (space) { // 개별 삭제 기능(우측)
      this.selectedSpaceList = this.selectedSpaceList.filter((param) => param !== space);
      const index = this.spaceList.indexOf(space);
      this.checkboxList[index] = false;
      this.checkboxAll = false;
    },
    selectAll (e) { // 전체 선택 기능(좌측)
      if (this.checkboxAll) { // 전체선택값이 true에서 false로 바뀔때
        this.deselectAll();
      } else { // 전체선택값이 false에서 true로 바뀔때
        this.selectedSpaceList = this.spaceList; // 전체스페이스 넣어주기
        for (const i in this.checkboxList) {
          this.checkboxList[i] = true;
        }
        this.checkboxAll = true;
      }
    },
    selcetSpace (i, space, e) { // 개별 선택 기능(좌측)
      if (this.checkboxList[i]) { // true에서 false로 바뀔때
        this.checkboxList[i] = false;
        this.selectedSpaceList = this.selectedSpaceList.filter((param) => param !== space);
        this.checkboxAll = false;
      } else {
        this.selectedSpaceList.push(space);
        const count = this.checkboxList.filter((param) => param === false);
        if (count.length === 1) this.checkboxAll = true;
        this.checkboxList[i] = true;
      }
    },
    applySpaceByBuildingSound () {
      const brandSpaceMusicId = this.brandSpaceMusicId;
      let spaceId = null;
      var array = [];
      // selectedSpaceList에 값이 있는 경우 spaceId를 배열에 담아서 String로 변환
      if (this.selectedSpaceList[0] != null) {
        for (const i in this.selectedSpaceList) {
          array.push(this.selectedSpaceList[i].spaceId);
        }
        spaceId = array.toString();
      }

      if (spaceId === null && this.playAt === 'Y') this.updateBrandMusicPlayAt();

      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/player/updateApplySpaceByBuildingSound", { brandSpaceMusicId, spaceId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.$emit('closeModal');
            this.$emit('getList');
          }
        })
        .catch(err => {
          console.log(err);
          alert("스페이스 등록에 실패하였습니다. 다시 시도해주세요.");
        });
    },
    getSubscribeSpaceList () {
      const userId = VueCookies.get("userId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/profile/subscribeSpaceList", { userId }, { headers })
        .then(res => {
          if (res.data.resultCd === '0000') {
            this.spaceList = res.data.result;

            // 구독중 스페이스 갯수만큼 길이를 가진 checkboxList생성
            this.spaceList.forEach(() => { this.checkboxList.push(false); });

            // 선택된 스페이스만큼 좌측 checkbox에 체크
            for (const j in this.spaceIdList) {
              for (const k in this.spaceList) {
                const index = this.spaceList[k].spaceId.indexOf(this.spaceIdList[j]);
                if (index === 0) {
                  this.checkboxList[k] = true;
                  this.selectedSpaceList.push(this.spaceList[k]);
                }
              }
            }

            // 전체 스페이스가 선택되어 있는 경우 checkboxAll값 true
            const count = this.checkboxList.filter((param) => param === false);
            if (count.length === 0) this.checkboxAll = true;
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    updateBrandMusicPlayAt () {
      const brandMusicId = this.brandMusicId;
      const spaceId = VueCookies.get("userId");
      const aToken = VueCookies.get("aToken");
      const headers = { "X-AUTH-TOKEN": aToken };

      axios
        .post("/api/player/updateBrandSpaceMusicPlayAt", { spaceId, brandMusicId }, { headers })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
</script>

<style scoped>
  .building_modal { background: rgba(0, 0, 0, 0.7); }
  .building_modal_box { width: 800px;height: 526px;padding:0;border-radius: 20px; overflow: hidden; }
  .building_modal_title { width: 100%;height: 60px;padding:16px 32px;margin: 0; border-bottom: solid rgba(255, 255, 255, 0.16) 1px; color: #fff; }
  .building_modal_title h2 { font-size: 20px;font-weight: 700;line-height: 29px; }
  .building_modal_title .building_sound_title { display: flex; align-items: center; }
  .building_modal_title .building_sound_title .modal_close_btn { margin-left: 24px; cursor: pointer; }
  .building_modal_title .building_sound_title > img:nth-child(1) { margin-right: 6px; }

  .building_modal_body { display: flex; flex-direction: column; width: 100%;height: 389px;overflow: hidden; display: flex; flex-direction: row;  }
  .building_modal_body .body_left { width: 240px; height: 100%; border-right: solid rgba(255, 255, 255, 0.16) 1px; }
  .building_modal_body .body_left ul { height: 20px; }
  .building_modal_body .body_left ul li { display: flex; flex-direction: row; align-items: center; }
  .building_modal_body .body_left ul li span:nth-child(1) { margin-right: 8px;}
  .building_modal_body .body_left ul li span:nth-child(2) { margin-right: 2px; color: #fff; font-weight: 400; font-size: 14px; line-height: 20px; display:-webkit-box; overflow:hidden; text-overflow: ellipsis;  -webkit-line-clamp: 1; -webkit-box-orient: vertical; text-align: left !important; }
  .building_modal_body .body_left ul li span:nth-child(3) { font-weight: 600; font-size: 17px; line-height: 21px; letter-spacing: -0.3px; color: #FD4E6D; }
  .building_modal_body .body_left ul li span input { height: auto !important; }
  .building_modal_body .body_left .body_left_top { width: 100%; height: 48px; padding: 16px 0 12px 32px; border-bottom: solid rgba(255, 255, 255, 0.16) 1px; }
  .building_modal_body .body_left .body_left_body { width: 100%; height: 341px; padding: 14px 32px; overflow-y: auto; }
  .building_modal_body .body_left .body_left_body::-webkit-scrollbar { display: block; }
  .building_modal_body .body_left .body_left_body::-webkit-scrollbar-thumb {background:#666; border-radius:5px;}
  .building_modal_body .body_left .body_left_body::-webkit-scrollbar-track {background:transparent;}
  .building_modal_body .body_left .body_left_body ul li { margin-bottom: 20px; }

  .building_modal_body .body_right { display: flex; flex-direction: column; width: 560px; height: 100%; padding: 32px 22px 32px 32px; background: #151515; }
  .building_modal_body .body_right .body_right_top {display: flex; flex-direction: row; justify-content: space-between !important; align-items: center; width: 100%; margin-bottom: 10px; padding-right: 15px;}
  .building_modal_body .body_right .body_right_top span:nth-child(1) { color: #fff; margin-right: 2px; }
  .building_modal_body .body_right .body_right_top span:nth-child(2) { color: #FD4E6D; }
  .building_modal_body .body_right .body_right_top div:nth-child(2) { color: #FD4E6D; cursor: pointer; font-size: 14px; }
  .building_modal_body .body_right .body_right_body { overflow-y: auto; }
  .building_modal_body .body_right .body_right_body::-webkit-scrollbar { display: block; }
  .building_modal_body .body_right .body_right_body::-webkit-scrollbar-thumb {background:#666; border-radius:5px;}
  .building_modal_body .body_right .body_right_body::-webkit-scrollbar-track {background:transparent;}
  .building_modal_body .body_right .body_right_body ul { padding-right: 10px; }
  .building_modal_body .body_right .body_right_body ul li { display: flex; flex-direction: row; justify-content: space-between !important; align-items: center; width: 100%; height: 48px; padding: 12px; margin-bottom: 10px; background: rgba(255, 255, 255, 0.08); border: 0.5px solid #FF4B6B; border-radius: 8px; }
  .building_modal_body .body_right .body_right_body ul li span:nth-child(1) {color: #fff; display:-webkit-box; overflow:hidden; text-overflow: ellipsis;  -webkit-line-clamp: 1; -webkit-box-orient: vertical; text-align: left !important;}
  .building_modal_body .body_right .body_right_body ul li span:nth-child(2) img {cursor: pointer;width: 10.8px;height: 10.8px;}
  .building_modal_body .body_right .body_right_body > span { display: block; text-align: center; margin-top: 25%; font-weight: 500; font-size: 14px; line-height: 20px; letter-spacing: -0.5px; color: rgba(255, 255, 255, 0.6); }

  .building_modal_bottom { width: 100%; height: 77px; padding:16px 32px; border-top: solid rgba(255, 255, 255, 0.16) 1px; display: flex; justify-content: flex-end !important; align-items: center; }
  .building_modal_bottom button:nth-child(1) { background: transparent; border-radius: 10px; margin-right: 8px; }
  .building_modal_bottom button:nth-child(1):hover { background: rgba(255, 255, 255, 0.04); }
  .building_modal_bottom button:nth-child(2) { width: 90px; height: 46px; line-height: 46px; text-align: center; background: #FF2D55; border-radius: 8px; color: #fff; font-size: 16px; font-weight: 500; }

  input[type="checkbox"] { display: none; }
  span.check { display: flex; align-items: center; cursor: pointer; }
  span.check img { width: 18px; height: 18px;}
</style>
