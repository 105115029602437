<template>
    <div @click="hideDelBox()">
        <MusicAlert v-if="musicAlertStatus" @closeAlert="musicAlertStatus = false" />
        <InsertSound v-if="isInsertSound" @closeModal="isInsertSound = false" @getList="getBuildingSoundList()" />
        <UpdateSound v-if="isUpdateSound" @closeModal="isUpdateSound = false" @getList="getBuildingSoundList()" :musicInfo="musicInfo" />
        <SelectSpace
            v-if="isSelectSpace"
            @closeModal="isSelectSpace = false"
            @getList="getBuildingSoundList()"
            :brandSpaceMusicId="brandSpaceMusicId"
            :brandMusicNm="brandMusicNm"
            :brandMusicId="brandMusicId"
            :playAt="playAt"
            :spaceIdList="spaceIdList"
        />
        <DeleteSound v-if="isDeleteSound" @closeConfirm="isDeleteSound = false" @closeDeletePopup="successDelete()" :musicInfo="musicInfo" />
        <SetTimebuildingSound v-if="isSetTime" :isbuilding="false" @closeModal="isSetTime = false" @getList="getBuildingSoundList()" :musicInfo="musicInfo" />
        <ReleaseAlert v-if="isReleaseAlert" @closeModal="isReleaseAlert = false" />
        <div class="brand_modal_box">
            <div class="brand_modal_title modal_title">
                <h1>홍보/방송 음원 관리</h1>
                <button @click="addSound()">
                    <img src="/media/img/add_building_sound.svg" alt="" />
                    음원추가
                </button>
            </div>
            <div class="building group_line">
                <div>
                    <p>
                        총 <span>{{ buildingSoundList.length }}</span
                        >개
                    </p>
                </div>
                <div>
                    <input type="text" placeholder="검색어를 입력하세요." v-model="search" @keyup.enter="searchBuildingSound()" />
                    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" @click="searchBuildingSound()">
                        <path
                            d="M16.031 14.617L20.314 18.899L18.899 20.314L14.617 16.031C13.0237 17.3082 11.042 18.0029 9 18C4.032 18 0 13.968 0 9C0 4.032 4.032 0 9 0C13.968 0 18 4.032 18 9C18.0029 11.042 17.3082 13.0237 16.031 14.617ZM14.025 13.875C15.2941 12.5699 16.0029 10.8204 16 9C16 5.132 12.867 2 9 2C5.132 2 2 5.132 2 9C2 12.867 5.132 16 9 16C10.8204 16.0029 12.5699 15.2941 13.875 14.025L14.025 13.875Z"
                            fill="white"
                        />
                    </svg>
                </div>
            </div>
            <div class="table_top">
                <table class="brand_table">
                    <colgroup>
                        <col width="33%" />
                        <col width="10%" />
                        <col width="15%" />
                        <col width="26%" />
                        <col width="12%" />
                        <col width="4%" />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>홍보/방송 음원 명</th>
                            <th>미리듣기</th>
                            <th>적용/전체<br />(스페이스 수)</th>
                            <th>시간설정</th>
                            <th>배포여부</th>
                            <th></th>
                        </tr>
                    </thead>
                </table>
            </div>
            <div class="brand_scroll_body">
                <table class="brand_table" v-if="buildingSoundList.length < 1">
                    <colgroup>
                        <col width="100%" />
                    </colgroup>
                    <tbody>
                        <tr>
                            <td class="empty_building_sound">
                                <img src="/media/img/empty_building_sound.png" alt="" />
                                <p>등록된 음원이 없습니다.</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="brand_table" v-else>
                    <colgroup>
                        <col width="34%" />
                        <col width="10%" />
                        <col width="13%" />
                        <col width="28%" />
                        <col width="15%" />
                        <col width="5%" />
                    </colgroup>
                    <tbody>
                        <template v-for="(item, index) in buildingSoundList">
                            <tr :key="index">
                                <td class="sound_title" @click="updateSound(item)" v-html="item.brandMusicNm" />
                                <td class="preview">
                                    <div class="play">
                                        <img v-bind:id="'img' + index" src="/media/img/brand_Play.svg" alt="" @click="onPlay(item.brandMusicId, $event)" />
                                        <div class="sound_updown">
                                            <span hidden></span>
                                            <span v-bind:style="{ width: $store.state.playNav + '%' }" hidden></span>
                                            <input v-bind:id="'seekslider' + index" type="range" min="0" max="100" step="1" value="0" hidden />
                                            <video v-bind:id="'bVideo' + index" width="100%" height="1" hidden playsinline></video>
                                        </div>
                                    </div>
                                </td>
                                <td class="space_applied" v-if="item.spaceIdList != null" @mouseover="showIcon($event)" @mouseleave="hideIcon($event)" @click="applySpace(item)">
                                    <span>{{ item.spaceIdList.length }}</span
                                    >/{{ totalSpace }}<img src="/media/img/icon_update.svg" alt="" />
                                </td>
                                <td v-else>
                                    <button @click="applySpace(item)">설정하기</button>
                                </td>
                                <td class="select-time">
                                    <div class="select-box-area" v-if="item.playType === 'R'" @mouseover="showIcon($event)" @mouseleave="hideIcon($event)" @click="timeSetting(item)">
                                        <span class="play_type_r">반복</span><span>{{ item.startTime }}부터</span><span>{{ item.repeatTime }}시간마다</span><img src="/media/img/icon_update.svg" alt="" />
                                    </div>
                                    <div class="select-box-area" v-else-if="item.playType === 'S'" @mouseover="showIcon($event)" @mouseleave="hideIcon($event)" @click="timeSetting(item)">
                                        <span class="play_type_s">지정</span><span>{{ item.selectTime }}</span
                                        ><img src="/media/img/icon_update.svg" alt="" />
                                    </div>
                                    <div v-else style="padding-left: 7%"><button @click="timeSetting(item)">설정하기</button></div>
                                </td>
                                <td class="alarm">
                                    <div class="alarm_box">
                                        <span :class="{ active1: item.playAt === 'Y' }" @click="updateBrandMusicPlayAt(item)"></span>
                                        <span class="move" :class="{ active1_1: item.playAt === 'Y' }" @click="updateBrandMusicPlayAt(item)"></span>
                                    </div>
                                </td>
                                <td @click="showDelBox(index)">
                                    <img src="/media/img/setting_icon.svg" />
                                    <div class="setting_show_box" :id="'box' + index">
                                        <p @click="deleteSound(item)"><img src="/media/img/icon_delete_16px.svg" alt="" />삭제</p>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
                <div class="building_notice_txt" :class="{ active: fadeOut }">음원의 배포가 중단되었습니다.</div>
            </div>
        </div>
    </div>
</template>

<script>
import MusicAlert from '@/components/modal/Space/PreferMusicAlert.vue';
import InsertSound from '@/components/modal/buildingSpace/InsertSound.vue';
import UpdateSound from '@/components/modal/buildingSpace/UpdateSound.vue';
import SelectSpace from '@/components/modal/buildingSpace/selectBuildingSpace.vue';
import DeleteSound from '@/components/modal/Player/mysound/confirm/DeleteConfirm.vue';
import SetTimebuildingSound from '@/components/modal/buildingSpace/setTime.vue';
import ReleaseAlert from '@/components/modal/buildingSpace/releaseAlert.vue';
import { defineComponent } from '@vue/composition-api';
import axios from 'axios';
import VueCookies from 'vue-cookies';
import Hls from 'hls.js';
import $ from 'jquery';

export default defineComponent({
  data () {
    return {
      buildingSoundListData: [],
      buildingSoundList: [],
      totalSpace: 0,
      musicAlertStatus: false,
      selectTime: 'Y',
      repeatTime: 'N',
      musicInfo: {
        brandMusicId: String,
        fileName: String,
        soundTitle: String,
        mySoundDetail: {
          brandSpaceMusicId: String,
          selectTime: '09:00',
          repeatTime: '1',
          startTime: '09:00',
          endTime: '23:55',
          playType: String
        }
      },
      playUrl: null,
      isInsertSound: false,
      isUpdateSound: false,
      isDeleteSound: false,
      isSelectSpace: false,
      isSetTime: false,
      isReleaseAlert: false,
      isIcon: false,
      fadeOut: false,
      search: '',
      brandSpaceMusicId: '',
      brandMusicNm: '',
      brandMusicId: '',
      playAt: '',
      spaceIdList: [],
      timeOut: null,
      preOpenValue: null
    };
  },
  components: {
    MusicAlert,
    InsertSound,
    UpdateSound,
    DeleteSound,
    SelectSpace,
    SetTimebuildingSound,
    ReleaseAlert
  },
  async mounted () {
    await this.getBuildingSoundList();
  },
  methods: {
    successDelete () {
      this.isDeleteSound = false;
      this.getBuildingSoundList();
    },
    showIcon (e) {
      $(e.currentTarget).addClass('on');
    },
    hideIcon (e) {
      $(e.currentTarget).removeClass('on');
    },
    showInfo (txt) {
      clearTimeout(this.timeOut);
      this.fadeOut = true;
      const noticeTxt = document.getElementsByClassName('building_notice_txt');
      $(noticeTxt).removeClass('active');
      $(noticeTxt).outerWidth(); // 애니메이션 효과 초기화를 위해 class삭제 추가 과정에 동작을 하나 넣어줌
      $(noticeTxt).addClass('active');
      noticeTxt[0].innerText = txt;

      this.timeOut = setTimeout(() => {
        this.fadeOut = false;
      }, 3990);
    },
    hideDelBox () {
      setTimeout(() => {
        $('.setting_show_box').hide();
        this.preOpenValue = null;
      }, 100);
    },
    showDelBox (i) {
      if (this.preOpenValue === i) return false;
      setTimeout(() => {
        if (this.preOpenValue === null) {
          const box = $('#box' + i);
          box.css('display', 'block');
          this.preOpenValue = i;
        }
      }, 200);
    },
    timeSetting (sound) {
      // 지정/반복 시간 설정 팝업
      this.musicInfo.brandMusicId = sound.brandMusicId;
      this.musicInfo.mySoundDetail.playType = sound.playType;
      this.musicInfo.mySoundDetail.selectTime = sound.selectTime;
      this.musicInfo.mySoundDetail.repeatTime = sound.repeatTime;
      this.musicInfo.mySoundDetail.startTime = sound.startTime;
      this.musicInfo.mySoundDetail.endTime = sound.endTime;
      this.isSetTime = true;
    },
    applySpace (sound) {
      // 적용 스페이스 설정 팝업
      this.brandMusicId = sound.brandMusicId;
      this.brandMusicNm = sound.brandMusicNm;
      this.brandSpaceMusicId = sound.brandSpaceMusicId;
      this.playAt = sound.playAt;
      sound.spaceIdList == null ? (this.spaceIdList = []) : (this.spaceIdList = sound.spaceIdList.map(item => item.spaceId));
      this.isSelectSpace = true;
    },
    deleteSound (item) {
      // 홍보/방송 음원 삭제 팝업
      this.musicInfo.brandMusicId = item.brandMusicId;
      this.musicInfo.fileName = item.brandOriginalNm;
      this.musicInfo.soundTitle = item.brandMusicNm;
      this.musicInfo.mySoundDetail.brandSpaceMusicId = item.brandSpaceMusicId;
      this.isDeleteSound = true;
    },
    updateSound (item) {
      // 홍보/방송 음원 수정 팝업
      this.musicInfo.brandMusicId = item.brandMusicId;
      this.musicInfo.fileName = item.brandOriginalNm;
      this.musicInfo.soundTitle = item.brandMusicNm;
      this.musicInfo.mySoundDetail.brandSpaceMusicId = item.brandSpaceMusicId;
      this.isUpdateSound = true;
    },
    addSound () {
      // 홍보/방송 음원 등록 팝업
      this.isInsertSound = true;
    },
    searchBuildingSound () {
      // 음악검색
      this.buildingSoundList = this.buildingSoundListData.filter((music) => {
        return music.brandMusicNm.includes(this.search);
      });
    },
    async getBuildingSoundList () {
      // 홍보/방송 음원 가져오기
      const spaceId = VueCookies.get('userId');
      const aToken = VueCookies.get('aToken');
      const headers = { 'X-AUTH-TOKEN': aToken };

      await axios
        .post('/api/player/selectBuildingSoundList', { spaceId }, { headers })
        .then((res) => {
          if (res.data.resultCd === '0000') {
            const result = res.data.result;
            this.buildingSoundListData = result.listResult;
            this.buildingSoundList = result.listResult;
            this.totalSpace = result.total;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onPlay (url, e) {
      // 미리듣기
      if (!this.$store.state.isPlay) {
        var videoId = $(e.currentTarget.parentElement).find('video')[0].id;
        var video = document.getElementById(videoId);
        var seek = $(e.currentTarget.parentElement).find('input')[0].id;
        var imgId = e.currentTarget.id;
        if (this.playUrl == null || this.playUrl !== url) {
          this.playerListener(url, seek, videoId, imgId);
        }
        $(e.currentTarget).attr('src', (index, attr) => {
          if (attr.match('_Play')) {
            video.play();
            return attr.replace('_Play.svg', '_Pause.svg');
          } else {
            video.pause();
            return attr.replace('_Pause.svg', '_Play.svg');
          }
        });
      } else {
        this.musicAlertStatus = true;
      }
    },
    playerListener (url, seek, videoId, imgId) {
      this.playUrl = url;
      // const _store = this.$store;
      const config = {
        // debug: true,
        // startPosition: 150,
        enableWorker: true,
        lowLatencyMode: true,
        backBufferLength: 90
      };

      let hls = null;
      const seekslider = document.getElementById(seek);
      let video = document.getElementById(videoId);

      seekslider.addEventListener('click', vidSeek, false);
      video.addEventListener('timeupdate', seektimeupdate, false);
      video.addEventListener('ended', () => {
        $('#' + imgId).attr('src', (index, attr) => {
          video.pause();
          return attr.replace('_Pause.svg', '_Play.svg');
        });
      });

      function vidSeek () {
        var seekto = video.duration * (seekslider.value / 100);
        video.currentTime = seekto;
      }

      function seektimeupdate () {
        var newtime = video.currentTime * (100 / video.duration);
        seekslider.value = newtime;
        // _store.commit("setPlayNav", newtime);
      }

      // 최초 음원 목록 재생
      playMusic(url);

      function playMusic (url) {
        const tg = window.location.host.indexOf('localhost') !== -1 ? 'http://13.209.152.125:10084/stream/getMusic?' + url : '/stream/getMusic?' + url;
        // const tg = "http://13.209.152.125:10084" + url;
        if (Hls.isSupported()) {
          if (hls != null) hls.destroy(); // removed all listeners
          hls = new Hls(config);
          hls.loadSource(tg);
          hls.attachMedia(video);
          // addListener(); //add player listener
        } else if (video != null && video.canPlayType('application/vnd.apple.mpegurl')) {
          // iOS인 경우로 따로 대응해주어야 한다.
          let v = document.getElementById(videoId);
          const pa = video.parentElement;

          // programmatic 하게 플레이어를 생성해야 한다.
          pa.removeChild(v);
          v = document.createElement(videoId);
          v.id = 'video';
          v.playsInline = true;

          const s = document.createElement('source');
          s.type = 'application/vnd.apple.mpegurl';

          v.appendChild(s);
          pa.appendChild(v);
          video = v;
          // addListener(); //add video player listener
          v.querySelector('source').src = tg;
          v.play(); // autoplay for next song
        } else {
          // todo - temporary error message -> 나중에 에러를 위한 처리(서버 로깅 등)를 해야 한다.
          alert('Sorry! Music player has an error.');
        }
      }
    },
    updateBrandMusicPlayAt (music) {
      // 음원 배포 설정
      if (music.playAt === 'N' && (music.spaceIdList === null || music.playType === null)) {
        // 배포값이 N이고 스페이스 및 시간설정이 되어있지 않은 경우
        this.isReleaseAlert = true;
      } else {
        // 스페이스 및 시간설정이 되어있는 경우
        if (music.playAt === 'Y') {
          // 음원 배포/중단 알림 - 하단 text노출
          music.playAt = 'N';
          this.showInfo('"' + music.brandMusicNm + '"' + ' 음원의 배포가 중단되었습니다.');
        } else {
          music.playAt = 'Y';
          this.showInfo('"' + music.brandMusicNm + '"' + ' 음원이 배포되었습니다.');
        }
        const brandMusicId = music.brandMusicId;
        const spaceId = VueCookies.get('userId');
        const aToken = VueCookies.get('aToken');
        const headers = { 'X-AUTH-TOKEN': aToken };

        axios
          .post('/api/player/updateBrandSpaceMusicPlayAt', { spaceId, brandMusicId }, { headers })
          .then((res) => {
            if (res.data.resultCd === '0000') {
              this.$emit('getList');
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }
});
</script>

<style scoped>
.brand_modal_title h1 {
  color: #fff;
  font-size: 38px;
  font-weight: 600;
  letter-spacing: -0.5px;
  margin-bottom: 0 !important;
}
.brand_modal_title {
  margin-bottom: 42px !important;
  padding: 0 !important;
}
.brand_modal_title button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 48px;
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid rgba(255, 255, 255, 0.6);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  color: #000;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.5px;
  font-weight: 500;
}
.brand_modal_title button img {
  width: 13.5px;
  height: 13.5px;
  margin-right: 7.25px;
}
.brand_modal_box {
  width: 100% !important;
  height: 100% !important;
  max-width: 1200px;
  padding: 132px 0 0;
  margin: 0 auto 50px;
  border-radius: 0 0 8px 8px;
}
.modal_box {
  background: rgba(21, 21, 21, 0.4);
  padding-bottom: 50px;
}
.table_top {
  padding-left: 20px;
  background: #272727;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 8px 8px 0 0;
}
.brand_scroll_body {
  height: 90% !important;
  padding: 0 20px !important;
  background: #272727;
  border: 1px solid rgba(255, 255, 255, 0.08);
}
.brand_scroll_body .building_notice_txt {
  opacity: 0;
  position: relative;
  position: fixed;
  left: 50%;
  bottom: 90px;
  transform: translateX(-50%);
  z-index: -1;
  background: #fff;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 30px;
  padding: 10px 16px;
  color: #151515;
  font-size: 16px;
  font-weight: 500;
}
.brand_scroll_body .building_notice_txt.active {
  z-index: 5;
  opacity: 1;
  animation: fadeOut_txt 4s linear;
}
.select-box-dropDown p {
  margin: 0;
}
.modal {
  display: block;
}
.brand_modal {
  z-index: 50;
  background: rgba(21, 21, 21, 0.4);
}
.select-box::after {
  background: none;
}
.disabled {
  opacity: 0.3;
}
input[type='range']:focus {
  outline: none;
  box-shadow: none;
}
/* .brand_table tbody tr td .sound_updown input[type=range] {position:absolute; padding: 0; width:100%; -webkit-appearance: none; background-color: transparent; height: 3px; outline: none; cursor: pointer; margin-bottom: 14px;} */
input[type='range'] {
  position: absolute;
  padding: 0;
  width: 100%;
  -webkit-appearance: none;
  background-color: #ffffff;
  height: 3px;
  outline: none;
  cursor: pointer;
  margin-bottom: 14px;
}
input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: #ffffff;
  cursor: pointer;
  height: 14px;
  width: 14px;
  border-radius: 35px;
}

.building.group_line {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 16px;
}
.building.group_line > div:nth-child(1) {
  display: flex;
  align-items: center;
}
.building.group_line > div:nth-child(1) p {
  color: #fff;
  font-size: 16px;
}
.building.group_line > div:nth-child(1) p span {
  font-weight: 600;
  color: #fd4e6d;
}
.building.group_line > div:nth-child(1) button svg,
.brand_table button svg {
  margin-right: 13px;
}
.building.group_line > div:nth-child(2) {
  position: relative;
}
.building.group_line > div:nth-child(2) input {
  width: 300px;
  border: none;
  border-radius: 0;
  background: transparent;
  padding: 10px 0;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.64);
  font-size: 16px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.24);
}
.building.group_line > div:nth-child(2) input::placeholder {
  color: rgba(255, 255, 255, 0.64);
  font-size: 16px;
}
.building.group_line > div:nth-child(2) svg {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
}

.brand_table thead tr {
  border: 0 !important;
}
.brand_table thead tr th {
  vertical-align: middle;
  padding: 14px 0;
  font-size: 14px;
  line-height: 18px;
}
.brand_table tbody tr:last-child {
  border: none;
}
.brand_table tbody tr td {
  padding: 0;
}
.brand_table tbody tr td:nth-child(2) {
  text-align: center;
}
.brand_table tbody tr td.sound_title {
  cursor: pointer;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-align: left !important;
  line-height: 59px;
}
.brand_table tbody tr td.select-time {
  text-align: center;
}
.brand_table tbody tr td.select-time .select-box-area {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  cursor: pointer;
}
.select-box-area .play_type_r {
  background: rgba(175, 87, 255, 0.16);
  border-radius: 100px;
  padding: 2px 4px;
  margin-right: 8px;
  color: #af57ff;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
}
.select-box-area .play_type_s {
  background: rgba(255, 158, 45, 0.16);
  border-radius: 100px;
  padding: 2px 4px;
  margin-right: 8px;
  color: #ff9e2d;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
}
.select-box-area span:nth-child(2),
.select-box-area span:nth-child(3) {
  color: #fff;
  padding: 2px 4px;
  margin-right: 6px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
}
.brand_table tbody tr td.alarm .alarm_box {
  margin: 0 auto;
  float: none;
  width: 45px;
}
.brand_table tbody tr td.preview .play {
  margin: 0 auto;
  float: none;
}
.brand_table tbody tr td.preview .play img {
  width: 16px;
  height: 20px;
  margin: 0 auto;
  cursor: pointer;
}
.brand_table tbody tr td:nth-child(3) {
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 14px;
  line-height: 0px;
  letter-spacing: -0.5px;
  color: rgba(255, 255, 255, 0.6);
}
.brand_table tbody tr td:nth-child(3) span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: rgba(255, 255, 255, 0.87);
}
.brand_table tbody tr td:nth-child(6) img:hover {
  background: rgba(255, 255, 255, 0.08);
  cursor: pointer;
  border-radius: 50px;
}
.brand_table tbody tr td:nth-child(6) img:active {
  background: rgba(255, 255, 255, 0.16);
}
.brand_table tbody tr td:nth-child(6) div {
  position: absolute;
  display: none;
  justify-content: center;
  align-items: center;
  top: 77%;
  left: -248%;
  z-index: 3;
  padding: 0;
}
.brand_table tbody tr td:nth-child(6) div img {
  margin-right: 10px;
}
.brand_table tbody tr button {
  width: 66px;
  height: 28px;
  padding: 4px 6px;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 4px;
  color: rgba(255, 255, 255, 0.87);
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.5px;
}
.brand_table tbody tr button:hover {
  background: rgba(255, 255, 255, 0.12);
}
.brand_table tbody tr .empty_building_sound {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.brand_table tbody tr .empty_building_sound img {
  width: 40px;
  height: 37px;
  margin: 158px auto 20px auto;
}
.brand_table tbody tr .empty_building_sound p {
  margin: 0 auto 158px auto;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 350;
  font-size: 16px;
  line-height: 23px;
}
.brand_table tbody tr:last-child td div {
  top: 5%;
}
.brand_table tbody tr td.space_applied,
.brand_table tbody tr td .select-box-area {
  position: relative;
}
.brand_table tbody tr td.space_applied:hover,
.brand_table tbody tr td .select-box-area:hover {
  background: rgba(255, 255, 255, 0.04);
}
.brand_table tbody tr td.space_applied img,
.brand_table tbody tr td .select-box-area img {
  position: absolute;
  left: 80%;
  bottom: 40%;
  display: none;
}
.brand_table tbody tr td.space_applied.on img {
  position: absolute;
  left: 80%;
  bottom: 40%;
  display: block;
}
.brand_table tbody tr td .select-box-area.on {
  border-radius: 0;
}
.brand_table tbody tr td .select-box-area.on img {
  position: absolute;
  left: 90%;
  bottom: 40%;
  display: block;
}

@keyframes fadeOut_txt {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>
