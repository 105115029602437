<template>
    <!-- -->
    <ul class="set_time_picker_setting cursor" v-click-outside="outSideClick" @click.capture="isActive" :class="{ disabled: disabled, active: active }">
        <li class="set_time_picker_setting_item" :class="{ disabled: disabled }">{{ selectedTime }} {{ text }}</li>
        <li class="set_time_picker_setting_item">
            <img class="set_time_picker_setting_arrow_icon" :class="{ disabled: disabled, active: active }" src="media/img/drop_down.svg" alt="drop_down" />
        </li>
        <li class="setting_drop_box_down" :class="{ active: active }">
            <div class="optionList">
                <date-time-picker v-model="selectedTime" type="time" minute-interval="5" />
            </div>
            <div class="accept_btn" @click="setTime">
                <p>확인</p>
            </div>
        </li>
    </ul>
</template>

<script>
import DateTimePicker from 'vue-drumroll-datetime-picker';
import 'vue-drumroll-datetime-picker/dist/style.css';
import vClickOutside from 'v-click-outside';
export default {
  name: 'SetTimePicker',
  emits: ['setTime'],
  props: {
    time: {
      type: String,
      default: '',
      required: false
    },
    playType: {
      type: String,
      default: '',
      required: false
    },
    disabled: {
      type: Boolean,
      default: true,
      required: false
    },
    text: {
      type: String,
      default: '',
      required: false
    }
  },
  components: {
    DateTimePicker
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  created () {
    this.selectedTime = this.time;
  },
  data () {
    return {
      selectedTime: '',
      active: false
    };
  },
  mounted () {},

  methods: {
    isActive ($event) {
      if ($event.target.className) {
        if ($event.target.className.includes('set_time_picker_setting') || $event.target.className.includes('set_time_picker_setting_arrow_icon')) {
          this.active = !this.active;
        }
      }
    },
    outSideClick () {
      this.active = false;
    },
    setTime () {
      this.active = false;
      this.$emit('setTime', this.selectedTime);
    }
  }
};
</script>
<style src="@/assets/css/drumrollpicker.css"></style>
<style scoped>
.set_time_picker_setting {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.08);
  border: 1px solid rgba(255, 255, 255, 0.04);
  border-radius: 8px;
  position: relative;
  cursor: pointer;
}

.set_time_picker_setting.active {
  box-shadow: 0 0 0 1px #ff2d55;
}

.set_time_picker_setting.disabled {
  background: rgba(255, 255, 255, 0.08);
}

.set_time_picker_setting_item {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--whitefff);
}
.set_time_picker_setting_item.disabled {
  color: rgba(255, 255, 255, 0.15);
}
.set_time_picker_setting_arrow_icon {
  width: 12px;
  height: 6px;
  transition: all 0.3s;
}

.set_time_picker_setting_arrow_icon.active {
  transform: rotate(180deg);
}

.set_time_picker_setting_arrow_icon.disabled {
  opacity: 0.4;
}

.setting_drop_box_down {
  position: absolute;
  width: 100%;
  height: 200px;
  max-height: 0;
  top: 110%;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  overflow: auto;
  transition: all 0.3s;
  z-index: 10;
  left: 0;
}

.setting_drop_box_down.active {
  box-shadow: 0 0 0 1px var(--ff2d55);
  max-height: 200px;
}

.accept_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  box-sizing: border-box;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  background: var(--2a2a2a);
  color: var(--ff2d55);
}
</style>
