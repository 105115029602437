<template>
    <div class="time_picker_setting">
        <div class="time_setting_box">
            <div class="label setting_box_header" @click="openTimeBox($event)">{{ selectedTime }}<span v-if="playType === 'R'"> 부터</span></div>
            <div class="setting_box_drop_down">
                <div class="optionList">
                    <date-time-picker v-model="selectedTime" type="time" minute-interval="5" />
                </div>
                <div class="accept_btn" @click="setTime">
                    <p>확인</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DateTimePicker from 'vue-drumroll-datetime-picker';
import 'vue-drumroll-datetime-picker/dist/style.css';

export default {
  name: 'timePicker',
  props: {
    time: String,
    playType: String
  },
  data () {
    return {
      selectedTime: ''
    };
  },
  components: {
    DateTimePicker
  },
  created () {
    this.selectedTime = this.time;
  },
  methods: {
    openTimeBox (e) {
      const $this = this;
      const parentClass = e.currentTarget.parentNode.classList;
      const list = Array.from(parentClass);
      if (list.indexOf('active') === -1) {
        parentClass.add('active');
      } else {
        parentClass.remove('active');
      }
      /* select_box_custom */
      const label = e.currentTarget;
      const handleClose = (e) => {
        // select 가 아닌경우 닫기
        if (
          !e.target.classList.contains('label') &&
          !e.target.classList.contains('top') &&
          !e.target.classList.contains('middle') &&
          !e.target.classList.contains('bottom') &&
          !e.target.classList.contains('v-drumroll-picker__divider') &&
          !e.target.classList.contains('vue-scroll-picker-group') &&
          !e.target.classList.contains('v-drumroll-picker__container')
        ) {
          $this.setTime();
          label.parentNode.classList.remove('active');
        }
      };
      window.addEventListener('click', (e) => {
        handleClose(e);
      });
    },

    setTime () {
      this.$emit('setTime', this.selectedTime);
    }
  }
};
</script>
<style src="@/assets/css/drumrollpicker.css"></style>
<style scoped>
/* select_box */
.time_picker_setting {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 52px;
  border-radius: 8px;
}

.time_picker_setting .time_setting_box {
  position: relative;
  width: 100%;
  border-radius: 8px 0 0 8px;
  background: transparent !important;
}

.time_picker_setting .time_setting_box .setting_box_header {
  position: relative;
  width: 100%;
  height: 52px;
  border-radius: 8px;
  padding: 16px 16px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  background: transparent !important;
}

.time_picker_setting .time_setting_box .setting_box_header::after {
  content: '';
  width: 12px;
  height: 6px;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  background: url(/media/img/drop_down.svg) no-repeat center 0;
  transition: all 0.3s;
  border-radius: 8px;
}

.time_picker_setting .time_setting_box .setting_box_drop_down {
  position: absolute;
  width: 100%;
  height: 200px;
  max-height: 0;
  top: 110%;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 8px;
  overflow: auto;
  transition: all 0.3s;
  z-index: 10;
}

.time_picker_setting .time_setting_box .setting_box_drop_down .optionList {
  width: 100%;
  max-height: 0;
  padding: 0;
  margin: 0;
  overflow: auto;
  transition: all 0.3s;
  color: rgba(255, 255, 255, 0.87);
}

.time_picker_setting .time_setting_box .setting_box_drop_down .optionList .optionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 6px 16px;
  cursor: pointer;
}

.time_picker_setting .time_setting_box .setting_box_drop_down .optionList .optionItem:hover {
  background: hsla(0, 0%, 100%, 0.06);
}

.time_picker_setting .time_setting_box .setting_box_drop_down .optionList .optionItem p {
  color: hsla(0, 0%, 100%, 0.87);
  font-size: 16px;
  margin: 0;
}

/* active */
.time_picker_setting .time_setting_box.active .setting_box_header {
  box-shadow: 0 0 0 1px #ff2d55;
}

.time_picker_setting .time_setting_box.active .setting_box_header::after {
  transform: rotate(180deg) translateY(3px);
}

.time_picker_setting .time_setting_box.active .setting_box_drop_down {
  box-shadow: 0 0 0 1px #ff2d55;
  max-height: 200px;
}

.time_picker_setting .time_setting_box.active .setting_box_drop_down .optionList {
  max-height: 200px;
}

.time_picker_setting .time_setting_box .setting_box_drop_down .accept_btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  box-sizing: border-box;
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  background: #2a2a2a;
  color: #ff2d55;
}

.disabled .setting_box_header {
  color: rgba(255, 255, 255, 0.15) !important;
}

.disabled .time_picker_setting .time_setting_box .setting_box_header::after {
  opacity: 0.4;
}
</style>
