<template>
    <div class="select-box-area circle_border" :class="{ disabled: disabled }">
        <div class="label select-box" :class="{ disabled: disabled }" style="width: 100%" @click="openRepeatBox($event)">{{ repeatTime }} 시간마다</div>
        <div class="select-box-dropDown" style="width: 100%">
            <ul class="optionList">
                <li class="optionItem" value="1" @mouseover="hoverEvent($event)" @mouseleave="leaveEvent($event)"><p>1시간</p></li>
                <li class="optionItem" value="2" @mouseover="hoverEvent($event)" @mouseleave="leaveEvent($event)"><p>2시간</p></li>
                <li class="optionItem" value="3" @mouseover="hoverEvent($event)" @mouseleave="leaveEvent($event)"><p>3시간</p></li>
                <li class="optionItem" value="6" @mouseover="hoverEvent($event)" @mouseleave="leaveEvent($event)"><p>6시간</p></li>
                <li class="optionItem" value="12" @mouseover="hoverEvent($event)" @mouseleave="leaveEvent($event)"><p>12시간</p></li>
            </ul>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'repeatTimeBox',
  data () {
    return {
      repeatTime: null
    };
  },
  props: {
    time: String,
    disabled: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  created () {
    this.repeatTime = this.time;
  },
  methods: {
    selectTime () {
      this.$emit('setTime', this.repeatTime);
    },
    hoverEvent (e) {
      $(e.currentTarget).find('img').css('display', 'block');
    },
    leaveEvent (e) {
      $(e.currentTarget).find('img').css('display', 'none');
    },
    openRepeatBox (e) {
      const label = e.currentTarget;
      const parentClass = label.parentNode.classList;
      const list = Array.from(parentClass);
      if (list.indexOf('active') === -1) {
        parentClass.add('active');
      } else {
        parentClass.remove('active');
      }
      /* select_box_custom */
      const handleClose = (e) => {
        // select 가 아닌경우 닫기
        if (e.target.parentNode.classList.contains('optionItem')) {
          this.repeatTime = e.target.parentNode.querySelector('p').innerText.replace('시간', '');
          this.selectTime();
          label.parentNode.classList.remove('active');
        } else if (
          !e.target.classList.contains('label') &&
          !e.target.classList.contains('top') &&
          !e.target.classList.contains('middle') &&
          !e.target.classList.contains('bottom') &&
          !e.target.classList.contains('v-drumroll-picker__divider') &&
          !e.target.classList.contains('vue-scroll-picker-group') &&
          !e.target.classList.contains('v-drumroll-picker__container')
        ) {
          label.parentNode.classList.remove('active');
        }
      };
      window.addEventListener('click', (e) => {
        handleClose(e);
      });
    }
  }
};
</script>

<style scoped>
.optionItem img {
  display: none;
}
.optionItem p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.5px;
  color: rgba(255, 255, 255, 0.87);
}
.label {
  background: transparent;
}
.disabled p,
.disabled .label.select-box {
  color: rgba(255, 255, 255, 0.15) !important;
}
/* 3.31 padding 영역 클릭 시 시간 선택이 안되어 스타일 수정 */
.select-box-dropDown .optionList .optionItem {
  padding: 0;
}
.select-box-dropDown .optionList .optionItem p {
  padding: 8px;
}
.select-box-dropDown .optionList .optionItem img {
  margin-right: 8px;
}

.circle_border {
  border-radius: 8px !important;
}
</style>
